import React from "react"
import PropTypes from "prop-types"
import { Router } from "@reach/router"
import { AuthUserContext } from '../../../components/Session'
import { PublicGallery } from '../../../Views'

const PhotoPage = (props) => {
  return (
    <>
      <AuthUserContext.Consumer>
        {authUser => (
          <Router>
            <PublicGallery path="/dashboard/gallery/:galleryID" authUser={authUser} galleryId={props['*']} />
          </Router>
        )}
      </AuthUserContext.Consumer>

    </>
  )
}

PhotoPage.propTypes = {
  "*": PropTypes.string,
}

export default PhotoPage
